<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Select the dataset to explore"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors }"
            :rules= {required:true}
            name="dataset"
          >
            <dataset-select
              label="Select a dataset"
              :dataset.sync="dataset"
              v-model="dataset"
              :withVarname="false"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              icon="table"
              pack="fa"
              expanded
              :minNumericVariable="2"
            />
          </validation-provider>
          <br/>
          <b-field label="Correlation threshold (in absolute value)">
            <b-slider
              v-model="thresholdCor"
              :min=0
              :max=1
              :step=0.01
              :tooltip=true
              :indicator=true
              type="is-grey"
            >
              <b-slider-tick :value="0">0</b-slider-tick>
              <b-slider-tick :value="1">1</b-slider-tick>
            </b-slider>
          </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button
              rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-search"></i></span>
              <span> Explore </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component title="2. Correlation plot" />
      <error :type='"notifications"' />
      <card-no-result v-show="!resultsLoaded">
        <strong>How to set options?<br></strong>
        Choose to dataset on which compute the correlation matrix between variables.
        You can filter this matrix in absolute value (i.e.) keep values higher than
        a threshold in absolute value to detect higher correlation between variables.
      </card-no-result>
      <div v-if="resultsLoaded">
        <card-component v-if="graphical">
          <plotly
            :height="700"
            :data="graphical"
          ></plotly>
          <nav class="level" >
            <div class="level-left" />
            <div class="buttons level-right">
              <r-save-for-report
                label="Save for report"
                :params="params"
              />
            </div>
          </nav>
        </card-component>
        <card-component v-if="png">
          <png :data=png />
          <nav class="level">
            <div class="level-left" />
            <div class="buttons level-right">
              <r-save-for-report
                label="Save for report"
                :params="params"
              />
            </div>
          </nav>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import { useUtilsStore } from '@/stores/utils'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RCorrPlot',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'error': () => import('@/components/ui/Error.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue')

  },
  mixins: [ranalysis],
  data () {
    return {
      dataset: null,
      thresholdCor: 0
    }
  },
  setup () {
    const utilsStore = useUtilsStore()
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore, utilsStore }
  },
  computed: {
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    params: function () {
      return {
        'func_name': 'r_corrplot',
        'dataset': this.dataset,
        'threshold': this.thresholdCor,
        'out_graph': true
      }
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
  .b-slider .b-slider-track {
    height: 0.5rem;
    background-color: #20BFF0;
  }
</style>
