<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Select the variable to explore"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors }"
            :rules= {required:true,oneOf:getAvailableVariables(dataset)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="X-axis"
          >
            <dataset-select
              label="X-axis"
              :dataset.sync="dataset"
              :varname.sync="varname"
              v-model="varname"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              @mouseover="$refs.fakeGraph.highlightXaxis()"
              @mouseout="$refs.fakeGraph.reinitGraph()"
              @select="option => selectVarname(option)"
              icon="xaxis" />
          </validation-provider>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-search"></i></span>
              <span> Explore </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component title="2. Plots and numerical summaries" />
      <error :type='"notifications"' />
      <card-component v-show="!resultsLoaded">
        <fake-graph
          ref="fakeGraph"
          analysis="univariate"
        />
      </card-component>
      <div v-if="resultsLoaded">
        <card-component>
          <nav class="level">
            <div class="level-left">
              <b-tabs @input="displayResult">
                <b-tab-item label="Plots" icon="bar-chart" value="plots" />
                <b-tab-item v-if="summaryTable" label="Summary" icon="table" value="summary" />
                <b-tab-item v-if="basicTable" label="Summary" icon="table" value="basic-table" />
                <b-tab-item v-if="basicTest" label="Normality test" icon-pack="icomoon" icon="basic-test" value="test" />
              </b-tabs>
            </div>
            <div class="buttons level-right" v-if="hasResults">
              <r-save-for-report :params="params" />
            </div>
          </nav>
        </card-component>
        <card-component v-if="currentResult==='plots'">
          <plotly
            v-if="graphicals.length===1"
            :data="graphicals[0]"
          ></plotly>
          <plotly-list
            v-else
            :data="graphicals"
          ></plotly-list>
        </card-component>
        <card-component v-if="currentResult==='summary'" :title="summaryTable.title">
          <summary-table :data="summaryTable"/>
        </card-component>
        <card-component v-if="currentResult==='basic-table'" :title="basicTable.title">
          <basic-table :data="basicTable"/>
        </card-component>
        <card-component v-if="currentResult==='test'">
          <basic-test :data="basicTest"/>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import FakeGraph from '@/components/ui/FakeGraph.vue'
import { useUtilsStore } from '@/stores/utils'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RUnivariate',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'basic-test': () => import('@/components/rresults/BasicTest.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'summary-table': () => import('@/components/rresults/SummaryTable.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'fake-graph': FakeGraph,
    'error': () => import('@/components/ui/Error.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      dataset: null,
      varname: '',
      currentResult: 'plots'
    }
  },
  setup () {
    const utilsStore = useUtilsStore()
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore, utilsStore }
  },

  computed: {
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    params: function () {
      return {
        'func_name': 'r_univariate',
        'dataset': this.dataset,
        'varname': this.varname
      }
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
    },
    selectVarname: function (value) {
      this.varname = value.name
    },
    getAvailableVariables: function (dataset) {
      let variables = []
      if (dataset in this.allVarnames) {
        variables = this.allVarnames[dataset].map(obj => obj.name)
      }
      return variables
    },
    afterRun: function () {
      this.displayResult('plots')
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
