<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Select the dataset to explore"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors }"
            :rules= {required:true}
            name="dataset"
          >
            <dataset-select
              label="Select a dataset"
              :dataset.sync="dataset"
              v-model="dataset"
              :withVarname="false"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              icon="table"
              pack="fa"
              expanded
            />
          </validation-provider>
          <br/>
          <b-field
                label=""
              >
                <b-switch
                  v-model="scale"
                  >
                  Scale numeric variables in the plot
                </b-switch>
              </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button
              rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-search"></i></span>
              <span> Explore </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component title="2. Plots and numerical summaries" />
      <error :type='"notifications"' />
      <card-no-result v-show="!resultsLoaded">
        <strong>How to set options?<br></strong>
        Choose to scale variables (centering and scaling to unit variance) by
        activating the corresponding option on the left if they have very
        different scales to obtain readable plots.
      </card-no-result>
      <div v-if="resultsLoaded">
        <card-component>
          <b-tabs @input="displayResult">
            <b-tab-item v-if="summaryTable" label="Numeric summary" icon="table" value="numsummary" />
            <b-tab-item v-if="postHocTest" label="Normality test"  icon-pack="icomoon" icon="basic-test" value="test" />
            <b-tab-item v-if="basicTable" label="Categorical summary" icon="table" value="catsummary" />
            <b-tab-item v-if="graphical" label="Plots" icon="bar-chart" value="plots" />
            <b-tab-item v-if="png" label="Correlation plot" icon="bar-chart" value="png" />
          </b-tabs>
        </card-component>
        <card-component v-if="currentResult==='plots'">
            <plotly
            v-if="graphicals.length===1"
            :data="graphicals[0]"
          ></plotly>
          <plotly-list
            v-else
            :data="graphicals"
          ></plotly-list>
        </card-component>
        <card-component v-if="currentResult==='png'">
          <png :data="png" />
        </card-component>
        <card-component v-if="currentResult==='numsummary'" :title="summaryTable.title">
          <summary-table :data="summaryTable"/>
        </card-component>
        <card-component v-if="currentResult==='catsummary'" :title="basicTable.title">
          <summary-table :data="basicTable"/>
        </card-component>
        <card-component v-if="currentResult==='test'">
          <post-hoc-tests :data="postHocTest"/>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import { useUtilsStore } from '@/stores/utils'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RUnivariateDataset',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'post-hoc-tests': () => import('@/components/rresults/PostHocTests.vue'),
    'summary-table': () => import('@/components/rresults/SummaryTable.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'png': () => import('@/components/rresults/PNG.vue'),
    'error': () => import('@/components/ui/Error.vue')

  },
  setup () {
    const utilsStore = useUtilsStore()
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore, utilsStore }
  },
  mixins: [ranalysis],
  data () {
    return {
      dataset: null,
      currentResult: 'numsummary',
      scale: false
    }
  },
  computed: {
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    params: function () {
      return {
        'func_name': 'r_univariate_dataset',
        'dataset': this.dataset,
        'scale': this.scale,
        'out_graph': true
      }
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
    },
    afterRun: function (data) {
      if (this.summaryTable) {
        this.displayResult('numsummary')
      } else {
        this.displayResult('catsummary')
      }
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
