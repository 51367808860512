<template>
  <div>
    <r-univariate v-if="selectedItem==='r_univariate'" />
    <r-bivariate v-if="selectedItem==='r_bivariate'" />
    <r-multivariate v-if="selectedItem==='r_multivariate'" />
    <r-univariate-dataset v-if="selectedItem==='r_univariate_dataset'"
      :analysisObj="analysisObj" />
    <r-corrplot v-if="selectedItem==='r_corrplot'" />
  </div>
</template>

<script>
import RUnivariate from '@/components/ranalyses/RUnivariate.vue'
import RUnivariateDataset from '@/components/ranalyses/RUnivariateDataset.vue'
import RBivariate from '@/components/ranalyses/RBivariate.vue'
import RMultivariate from '@/components/ranalyses/RMultivariate.vue'
import RCorrPlot from '@/components/ranalyses/RCorrPlot.vue'
import { menu } from '@/mixins/menu'
import { workflow } from '@/mixins/workflow'
import { useWorkspaceStore } from '@/stores/workspacestore'
import { useMenuStore } from '@/stores/menu'

export default {
  name: 'RVariate',
  components: {
    'r-univariate': RUnivariate,
    'r-bivariate': RBivariate,
    'r-multivariate': RMultivariate,
    'r-univariate-dataset': RUnivariateDataset,
    'r-corrplot': RCorrPlot
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },

  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          title: '1 variable',
          name: 'r_univariate',
          disabled: false
        },
        {
          title: '2 variables',
          name: 'r_bivariate',
          disabled: false
        },
        {
          title: 'up to 5 variables',
          name: 'r_multivariate',
          disabled: false
        },
        {
          title: 'All variables in a dataset',
          name: 'r_univariate_dataset',
          disabled: false
        },
        {
          title: 'Correlation plot of a dataset',
          name: 'r_corrplot',
          disabled: false
        }
      ],
      error: ''
    }
  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow()
      .then(() => {
        loadingComponent.close()
      })
      .catch(() => {
        loadingComponent.close()
      })
    if (this.analysisObj !== null && this.analysisObj.meta.func_name === 'r_univariate_dataset') {
      // ça sélectionne le bon onglet
      this.menuStore.selectItemName(this.analysisObj.meta.func_name)
    }
  }
}
</script>
