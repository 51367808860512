<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Select the variables to explore"
        class="tile is-child"
      >
        <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors }"
            :rules= {required:true,oneOf:getAvailableVariables(datasetxaxis)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="X-axis"
          >
            <dataset-select
              label="X-axis"
              :dataset.sync="datasetxaxis"
              :varname.sync="varxaxis"
              v-model="varxaxis"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              @mouseover="$refs.fakeGraph.highlightXaxis()"
              @mouseout="$refs.fakeGraph.reinitGraph()"
              @select="option => selectVarname(option, 'varxaxis')"
              icon="xaxis" />
          </validation-provider>
          <br />
          <validation-provider
            v-slot="{ errors }"
            :rules= {required:true,oneOf:getAvailableVariables(datasetyaxis)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="Y-axis"
          >
            <dataset-select
              label="Y-axis"
              :dataset.sync="datasetyaxis"
              :varname.sync="varyaxis"
              v-model="varyaxis"
              :type="{ 'is-danger': errors[0] }"
              :message="errors"
              @mouseover="$refs.fakeGraph.highlightYaxis()"
              @mouseout="$refs.fakeGraph.reinitGraph()"
              @select="option => selectVarname(option, 'varyaxis')"
              icon="yaxis" />
          </validation-provider>
          <br />
          <validation-provider
            v-slot="{ errors }"
            :rules= {oneOf:getAvailableVariables(datasetcolor)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="color"
          >
            <dataset-select
              label="Color"
              :dataset.sync="datasetcolor"
              :varname.sync="varcolor"
              v-model="varcolor"
              :message="errors"
              :type="{ 'is-danger': errors[0] }"
              @mouseover="$refs.fakeGraph.highlightColor()"
              @mouseout="$refs.fakeGraph.reinitGraph()"
              @select="option => selectVarname(option, 'varcolor')"
              icon="color" />
          </validation-provider>
          <br />
          <validation-provider
            v-slot="{ errors }"
            :rules= {oneOf:getAvailableVariables(datasetshape)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="shape"
          >
            <dataset-select
              label="Shape"
              :dataset.sync="datasetshape"
              :varname.sync="varshape"
              v-model="varshape"
              :message="errors"
              :type="{ 'is-danger': errors[0] }"
              @mouseover="$refs.fakeGraph.highlightShape()"
              @mouseout="$refs.fakeGraph.reinitGraph()"
              @select="option => selectVarname(option, 'varshape')"
              :minCatVariable="1"
              :vartype="'quali'"
              :uniquevals="6"
              :minlevel="2"
              labeltooltip="Categorical variables having between 2 and 6 distinct levels"
              icon="shape" />
          </validation-provider>
          <br />
          <validation-provider
            v-slot="{ errors }"
            :rules= {oneOf:getAvailableVariables(datasetsize)}
            :customMessages = "{oneOf: 'This value is not a valid variable name.'}"
            name="size"
          >
            <dataset-select
              label="Size"
              :dataset.sync="datasetsize"
              v-model="varsize"
              :varname.sync="varsize"
              :message="errors"
              :type="{ 'is-danger': errors[0] }"
              @mouseover="$refs.fakeGraph.highlightSize()"
              @mouseout="$refs.fakeGraph.reinitGraph()"
              @select="option => selectVarname(option, 'varsize')"
              :vartype="'quanti'"
              :minNumericVariable="1"
              :minlevel="2"
              labeltooltip="Non-constant quantitative variables"
              icon="size" />
          </validation-provider>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click.prevent="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-search"></i></span>
              <span> Explore </span>
            </b-button>
          </div>
        </validation-observer>
      </card-component>
    </div>
    <div class="column">
      <card-component title="2. Plot" />
      <error :type='"notifications"' />
      <card-component v-show="!resultsLoaded">
      Use color, shape and size can be useful to understand the relationships
      between two numerical variables represented on the X and Y axes.<br><br>
        <fake-graph
          ref="fakeGraph"
          analysis="multivariate"
        />
      </card-component>
      <card-component v-if="resultsLoaded">
        <plotly :data="graphical"></plotly>
        <nav class="level" v-if="hasResults">
          <div class="level-left" />
          <div class="buttons level-right">
            <r-save-for-report
              label="Save for report"
              :params="params"
            />
          </div>
        </nav>
      </card-component>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import Plotly from '@/components/rresults/Plotly.vue'
import FakeGraph from '@/components/ui/FakeGraph.vue'
import { useUtilsStore } from '@/stores/utils'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RMultivariate',
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'plotly': Plotly,
    'fake-graph': FakeGraph,
    'error': () => import('@/components/ui/Error.vue'),
    'r-save-for-report': () => import('@/components/RSaveForReport.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      datasetxaxis: null,
      datasetyaxis: null,
      datasetcolor: null,
      datasetshape: null,
      datasetsize: null,
      varxaxis: '',
      varyaxis: '',
      varcolor: '',
      varshape: '',
      varsize: ''
    }
  },
  setup () {
    const utilsStore = useUtilsStore()
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore, utilsStore }
  },
  computed: {
    allVarnames: function () {
      return this.utilsStore.allVarnames
    },
    params: function () {
      let params = {
        'func_name': 'r_multivariate_dotplot',
        'datasetxaxis': this.datasetxaxis,
        'varxaxis': this.varxaxis,
        'datasetyaxis': this.datasetyaxis,
        'varyaxis': this.varyaxis
      }
      if (this.datasetcolor !== null && this.varcolor !== '') {
        params['datasetcolor'] = this.datasetcolor
        params['varcolor'] = this.varcolor
      }
      if (this.datasetshape !== null && this.varshape !== '') {
        params['datasetshape'] = this.datasetshape
        params['varshape'] = this.varshape
      }
      if (this.datasetsize !== null && this.varsize !== '') {
        params['datasetsize'] = this.datasetsize
        params['varsize'] = this.varsize
      }
      return params
    }
  },
  methods: {
    getAvailableVariables: function (dataset) {
      let variables = []
      if (dataset in this.allVarnames) {
        variables = this.allVarnames[dataset].map(obj => obj.name)
      }
      return variables
    },
    selectVarname: function (value, varname) {
      this[varname] = value.name
    }
  }
}
</script>
