<template>
<svg
   width="100%"
   height="450"
   style="background: rgb(255, 255, 255) none repeat scroll 0% 0%;">
  <defs>
    <g
       transform="fill:#ffffff;"
       class="gradients"
       id="g3452">
      <linearGradient
         v-if="isMultivariate"
         x1="2092.1959"
         x2="2092.1959"
         y1="56.947344"
         y2="0"
         id="gbab5d3-cb8c3a45"
         gradientTransform="scale(0.40388187,2.4759715)"
         gradientUnits="userSpaceOnUse">
          <stop offset="0%" :stop-color="gradColor"/>
          <stop offset="100%" :stop-color="colorColor"/>
      </linearGradient>
    </g>
  </defs>
  <g
     class="bglayer"
     id="g3455" />
  <g
     class="layer-below"
     id="g3487">
    <g
       class="imagelayer"
       id="g3483" />
    <g
       class="shapelayer"
       id="g3485" />
  </g>
  <g
     class="cartesianlayer"
     id="g5605">
    <g
       class="subplot xy"
       id="g5603">
      <g
         class="layer-subplot"
         id="g3493">
        <g
           class="shapelayer"
           id="g3489" />
        <g
           class="imagelayer"
           id="g3491" />
      </g>
      <g
         class="gridlayer"
         id="g3513">
        <g
           class="x"
           id="g3499">
          <path
             class="xgrid crisp"
             transform="translate(351.88,0)"
             d="M0,26v378"
             style="stroke: rgb(235, 235, 235); stroke-opacity: 1; stroke-width: 1px;"
             id="path3495" />
          <path
             class="xgrid crisp"
             transform="translate(654.42,0)"
             d="M0,26v378"
             style="stroke: rgb(235, 235, 235); stroke-opacity: 1; stroke-width: 1px;"
             id="path3497" />
        </g>
        <g
           v-if="!isUnivariate"
           class="y"
           id="g3511">
          <path
             class="ygrid crisp"
             transform="translate(0,349.85)"
             d="M53,0h819"
             style="stroke: rgb(235, 235, 235); stroke-opacity: 1; stroke-width: 1px;"
             id="path3501" />
          <path
             class="ygrid crisp"
             transform="translate(0,269.62)"
             d="M53,0h819"
             style="stroke: rgb(235, 235, 235); stroke-opacity: 1; stroke-width: 1px;"
             id="path3503" />
          <path
             class="ygrid crisp"
             transform="translate(0,189.38)"
             d="M53,0h819"
             style="stroke: rgb(235, 235, 235); stroke-opacity: 1; stroke-width: 1px;"
             id="path3505" />
          <path
             class="ygrid crisp"
             transform="translate(0,109.15)"
             d="M53,0h819"
             style="stroke: rgb(235, 235, 235); stroke-opacity: 1; stroke-width: 1px;"
             id="path3507" />
          <path
             class="ygrid crisp"
             transform="translate(0,28.92)"
             d="M53,0h819"
             style="stroke: rgb(235, 235, 235); stroke-opacity: 1; stroke-width: 1px;"
             id="path3509" />
        </g>
      </g>
      <g
         class="plot"
         transform="translate(53,26)"
         clip-path="url('#clipbab5d3xyplot')"
         id="g5545">
        <g
           class="scatterlayer mlayer"
           id="g5543">
          <g
             class="trace scatter tracedf893e"
             style="stroke-miterlimit: 2; opacity: 1;"
             id="g5491">
            <g
               class="fills"
               id="g3529" />
            <g
               class="errorbars"
               id="g3531" />
            <g
               class="lines"
               id="g3533" />
            <g
               class="text"
               id="g5489" />
            <g
               v-for="(coord,index) in pointsCoords"
               :key="index"
               class="toyplot-Datum"
               :stroke="dataColor"
               :fill="dataColor"
               :opacity="getEltOpacity(coord.opacity)"
            >
               <circle :cx="coord.x" :cy="coord.y" :r="getEltSize(coord.size)" />
            </g>
          </g>
          <g
             class="trace scatter trace8458d7"
             style="opacity:1;stroke-miterlimit:2"
             id="g5527"
             transform="translate(-81,-35)">
            <g
               class="fills"
               id="g5493" />
            <g
               class="errorbars"
               id="g5495" />
            <g
               class="lines"
               id="g5497" />
            <g
               class="points"
               id="g5523">
               <g
                  v-for="(coord,index) in trianglesCoords"
                  :key="index"
                  class="toyplot-Datum"
                  :stroke="dataColor"
                  :fill="dataColor"
                  :opacity="coord.opacity"
               >
                 <polygon :points="coord.points"/>
               </g>
            </g>
            <g
               class="text"
               id="g5525" />
          </g>
        </g>
      </g>
      <g
         class="overplot"
         id="g5547" />
      <path
         class="xlines-above crisp"
         style="fill: none; stroke-width: 1px; stroke: rgb(0, 0, 0); stroke-opacity: 0;"
         d=""
         id="path5549" />
      <path
         class="ylines-above crisp"
         style="fill: none; stroke-width: 1px; stroke: rgb(0, 0, 0); stroke-opacity: 0;"
         d=""
         id="path5551" />
      <g
         class="overlines-above"
         id="g5553" />
      <g
         class="xaxislayer-above"
         id="g5567">
        <path
           class="xtick ticks crisp"
           style="stroke: rgb(51, 51, 51); stroke-opacity: 1; stroke-width: 1px;"
           d="M0,405v3.65296803652968"
           transform="translate(351.88,0)"
           id="path5555" />
        <path
           class="xtick ticks crisp"
           style="stroke: rgb(51, 51, 51); stroke-opacity: 1; stroke-width: 1px;"
           d="M0,405v3.65296803652968"
           transform="translate(654.42,0)"
           id="path5557" />
        <g
           transform="translate(-1.0337104,1.0002521)"
           class="indicatorlayer"
           id="g5810" />
        <g
           transform="translate(-1.0337104,1.0002521)"
           class="layer-above"
           id="g5818">
          <g
             class="imagelayer"
             id="g5812" />
        </g>
        <g
           v-if="isMultivariate"
           transform="translate(-11.03371,5.0002521)"
           class="infolayer"
           id="g5936">
          <g
             class="legend"
             pointer-events="all"
             transform="translate(888.38,264.11024)"
             id="g5866">
            <rect
               class="bg"
               style="fill:#ffffff;fill-opacity:1;stroke:#000000;stroke-width:1.88976002px;stroke-opacity:0;shape-rendering:crispEdges"
               width="87.110237"
               height="60.110237"
               x="0.94488192"
               y="0.94488192"
               id="rect5820" />
            <g
               class="scrollbox"
               clip-path="url(#legendbab5d3)"
               id="g5862">
              <g
                 class="groups"
                 id="g5840">
                <g
                   class="traces"
                   style="opacity:1"
                   transform="translate(1.8897638,16.389764)"
                   id="g5838">
                  <g
                     class="layers"
                     style="opacity:1"
                     id="g5834">
                    <g
                       class="legendfill"
                       id="g5824" />
                    <g
                       class="legendlines"
                       id="g5826" />
                    <g
                       class="legendsymbols"
                       id="g5832">
                      <g
                         class="legendpoints"
                         id="g5830">
                        <path
                           :fill="shapeColor"
                           :stroke="shapeColor"
                           inkscape:connector-curvature="0"
                           class="scatterpts"
                           transform="translate(20)"
                           style="opacity:1;fill-opacity:1;stroke-width:1.88976002px;stroke-opacity:1"
                           d="M 8,0 A 8,8 0 1 1 0,-8 8,8 0 0 1 8,0 Z"
                           id="path5828" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
              <g
                 class="groups"
                 transform="translate(0,10)"
                 id="g5860">
                <g
                   class="traces"
                   style="opacity:1"
                   transform="translate(1.8897638,35.389764)"
                   id="g5858">
                  <g
                     class="layers"
                     style="opacity:1"
                     id="g5854">
                    <g
                       class="legendfill"
                       id="g5844" />
                    <g
                       class="legendlines"
                       id="g5846" />
                    <g
                       class="legendsymbols"
                       id="g5852">
                      <g
                         class="legendpoints"
                         id="g5850">
                        <path
                           :fill="shapeColor"
                           :stroke="shapeColor"
                           inkscape:connector-curvature="0"
                           class="scatterpts"
                           transform="translate(20)"
                           style="opacity:1;fill-opacity:1;stroke-width:1.88976002px;stroke-opacity:1"
                           d="M -9.24,4 H 9.24 L 0,-8 Z"
                           id="path5848" />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
             class="cb8c3a45 colorbar"
             transform="translate(53,26)"
             id="g5910">
            <g
               class="cbfills"
               transform="translate(0,38)"
               id="g5872">
              <rect
                 class="cbfill"
                 style="fill:url(#gbab5d3-cb8c3a45)"
                 x="845"
                 width="23"
                 y="0"
                 height="141"
                 id="rect5870" />
            </g>
            <g
               class="cblines"
               transform="translate(0,38)"
               id="g5874" />
            <g
               class="cbaxis crisp"
               transform="translate(0,-26)"
               id="g5900">
              <path
                 :stroke="colorColor"
                 inkscape:connector-curvature="0"
                 class="ycb8c3a45tick ticks crisp"
                 style="stroke-width:1px;stroke-opacity:1"
                 d="m 868,0 h 2"
                 transform="translate(0,196.08)"
                 id="path5876" />
              <path
                 :stroke="colorColor"
                 inkscape:connector-curvature="0"
                 class="ycb8c3a45tick ticks crisp"
                 style="stroke-width:1px;stroke-opacity:1"
                 d="m 868,0 h 2"
                 transform="translate(0,161.28)"
                 id="path5878" />
              <path
                 :stroke="colorColor"
                 inkscape:connector-curvature="0"
                 class="ycb8c3a45tick ticks crisp"
                 style="stroke-width:1px;stroke-opacity:1"
                 d="m 868,0 h 2"
                 transform="translate(0,126.48)"
                 id="path5880" />
              <path
                 :stroke="colorColor"
                 inkscape:connector-curvature="0"
                 class="ycb8c3a45tick ticks crisp"
                 style="stroke-width:1px;stroke-opacity:1"
                 d="m 868,0 h 2"
                 transform="translate(0,91.68)"
                 id="path5882" />
              <g
                 class="ycb8c3a45tick"
                 id="g5886" />
              <g
                 class="ycb8c3a45tick"
                 id="g5890" />
              <g
                 class="ycb8c3a45tick"
                 id="g5894" />
              <g
                 class="ycb8c3a45tick"
                 id="g5898" />
            </g>
          </g>
        </g>
      </g>
      <g
         v-if="!isUnivariate"
         class="yaxislayer-above"
         id="g5599">
        <path
           class="ytick ticks crisp"
           style="stroke: rgb(51, 51, 51); stroke-opacity: 1; stroke-width: 1px;"
           d="M52,0h-3.65296803652968"
           transform="translate(0,349.85)"
           id="path5569" />
        <path
           class="ytick ticks crisp"
           style="stroke: rgb(51, 51, 51); stroke-opacity: 1; stroke-width: 1px;"
           d="M52,0h-3.65296803652968"
           transform="translate(0,269.62)"
           id="path5571" />
        <path
           class="ytick ticks crisp"
           style="stroke: rgb(51, 51, 51); stroke-opacity: 1; stroke-width: 1px;"
           d="M52,0h-3.65296803652968"
           transform="translate(0,189.38)"
           id="path5573" />
        <path
           class="ytick ticks crisp"
           style="stroke: rgb(51, 51, 51); stroke-opacity: 1; stroke-width: 1px;"
           d="M52,0h-3.65296803652968"
           transform="translate(0,109.15)"
           id="path5575" />
        <path
           class="ytick ticks crisp"
           style="stroke: rgb(51, 51, 51); stroke-opacity: 1; stroke-width: 1px;"
           d="M52,0h-3.65296803652968"
           transform="translate(0,28.92)"
           id="path5577" />
        <g
           class="ytick"
           id="g5593" />
      </g>
      <g
         class="overaxes-above"
         id="g5601" />
      <text
         v-if="isMultivariate"
        :fill="colorColor"
         xml:space="preserve"
         style="font-style:normal;font-weight:normal;font-size:40px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill-opacity:1;stroke:none"
         x="885.00421"
         y="60.037388"
         id="text9859"><tspan
           sodipodi:role="line"
           id="tspan9857"
           x="885.00421"
           y="60.037388"
           style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:21.33333397px;font-family:sans-serif;-inkscape-font-specification:'sans-serif Bold'">Color</tspan></text>
      <text
         v-if="isMultivariate"
        :fill="shapeColor"
         xml:space="preserve"
         style="font-style:normal;font-weight:normal;font-size:21.33333397px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill-opacity:1;stroke:none"
         x="889.73151"
         y="265.96008"
         id="text9859-9"><tspan
           sodipodi:role="line"
           id="tspan9857-4"
           x="889.73151"
           y="265.96008"
           style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:21.33333397px;font-family:sans-serif;-inkscape-font-specification:'sans-serif Bold'">Shape</tspan></text>
      <text
         xml:space="preserve"
         style="font-style:normal;font-weight:normal;font-size:40px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill-opacity:1;stroke:none"
         x="440.07974"
         y="433.69791"
         :fill="xaxisColor"
         id="text9859-3"><tspan
           sodipodi:role="line"
           id="tspan9857-5"
           x="440.07974"
           y="433.69791"
           style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:21.33333397px;font-family:sans-serif;-inkscape-font-specification:'sans-serif Bold'">X-axis</tspan></text>
      <text
         v-if="!isUnivariate"
         xml:space="preserve"
         style="font-style:normal;font-weight:normal;font-size:49.25467682px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill-opacity:1;stroke:none;stroke-width:1.23136699"
         :fill="yaxisColor"
         x="-252.15727"
         y="38.148495"
         id="text9859-3-1"
         transform="rotate(-89.66157)"><tspan
           sodipodi:role="line"
           id="tspan9857-5-7"
           x="-252.15727"
           y="38.148495"
           style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:26.26916313px;font-family:sans-serif;-inkscape-font-specification:'sans-serif Bold';stroke-width:1.23136699">Y-axis</tspan></text>
      <path
         v-if="isUnivariate"
         :stroke="shapeColor"
         fill="none"
         inkscape:connector-curvature="0"
         style="stroke-width:3.13376689;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10"
         stroke-miterlimit="10"
         d="m 65,405 c 17.989381,-0.0832 35.97478,-0.25391 53.9562,-0.76611 17.195785,-0.49472 34.451396,-1.30026 51.535526,-3.65995 18.08909,-2.50416 35.85516,-6.66317 52.88346,-14.22821 16.9685,-7.54316 31.96696,-18.97389 45.8249,-32.34844 13.4711,-12.99363 25.25932,-28.95115 35.62784,-45.1976 10.67161,-16.71049 20.56158,-34.04703 29.7417,-51.8958 9.45929,-18.38723 18.51978,-37.02841 27.50052,-55.72649 8.80528,-18.33031 17.6225,-36.63438 26.7787,-54.73268 8.94884,-17.69555 18.61947,-35.264121 29.94112,-51.107793 5.86619,-8.208595 12.33056,-15.52409 19.5646,-22.125982 7.03863,-6.422396 15.53682,-10.038562 24.42983,-10.804695 9.02062,-0.77488 17.57865,4.290355 24.85255,9.780256 7.44142,5.616885 13.6386,13.51025 19.38516,21.206625 11.85601,15.843691 21.39505,33.902566 30.72272,51.755719 9.04057,17.3059 17.39521,35.08462 25.87744,52.74951 8.93288,18.60614 17.86178,37.22102 27.28119,55.5207 9.13228,17.75683 18.70323,35.27726 29.32697,51.91334 10.89093,17.0651 22.8267,33.11455 36.52512,47.32087 13.4711,13.9568 28.8524,24.83591 45.52977,32.95258 16.04729,7.81458 33.65782,12.07866 50.91742,14.469 17.74215,2.46479 35.54011,3.51548 53.3979,4.10651 17.65042,0.591 35.3168,0.71797 52.97518,0.8099 0.90526,0 1.79057,0.009 2.68784,0.0125"
         id="path2109-3" />
      <g v-if="isUnivariate">
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="155" y="395" width="30" height="10"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="185" y="385" width="30" height="20"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="215" y="375" width="30" height="30"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="245" y="355" width="30" height="50"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="275" y="310" width="30" height="95"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="305" y="260" width="30" height="145"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="335" y="230" width="30" height="175"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="365" y="140" width="30" height="265"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="395" y="100" width="30" height="305"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="425" y="70" width="30" height="335"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="455" y="50" width="30" height="355"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="485" y="80" width="30" height="325"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="515" y="130" width="30" height="275"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="545" y="180" width="30" height="225"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="575" y="270" width="30" height="135"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="605" y="310" width="30" height="95"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="635" y="360" width="30" height="45"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="665" y="370" width="30" height="35"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="695" y="390" width="30" height="15"/>
         <rect :fill-opacity="0.4" :stroke="shapeColor" :fill="shapeColor" x="725" y="395" width="30" height="10"/>
      </g>
      <g transform="rotate(180,573.18351,329.65573)">
        <g transform="rotate(180,183.27,132.25)">
          <g>
            <path
               transform="translate(-779.82702,-394.81146)"
               d="m 885.794,406.41143 h -13.37658 l -820.776384,0.11207 -0.0062,-1.38231 -0.0014,-1.58072 820.783964,0.19507 h 13.3766 c -0.82326,0.32557 -0.84993,2.90362 0,2.65589 z"
               :fill="xaxisColor"
               style="background:rgb(255, 255, 255) none repeat scroll 0% 0%;stroke-width:0.04997532" />
            <path
               d="m 108.129,10.99 -8.6,8.6 A 1.400425,1.400425 0 1 1 97.548,17.61 L 105.158,10 97.549,2.391 A 1.4007785,1.4007785 0 0 1 99.53,0.41 l 8.6,8.6 a 1.4,1.4 0 0 1 0,1.98 z"
               :fill="xaxisColor"
               inkscape:connector-curvature="0" />
            <path
               v-if="!isUnivariate"
               d="m -726.87528,-381.63331 8.58965,8.61033 a 1.4004256,1.4004256 0 1 1 -1.98238,1.97862 l -7.60085,-7.61915 -7.61814,7.59985 a 1.4007831,1.4007831 0 0 1 -1.97862,-1.98339 l 8.61034,-8.58965 a 1.4,1.4 0 0 1 1.98,0.002 z"
               :fill="yaxisColor"
               inkscape:connector-curvature="0" />
          </g>
        </g>
      </g>
      <g transform="matrix(4.2116472e-4,0.46850693,-0.99962167,0.02669245,305.97056,-113.0189)">
        <g transform="rotate(180,183.27,132.25)">
          <g>
            <path
               v-if="!isUnivariate"
               transform="translate(-779.82702,-394.81146)"
               sodipodi:nodetypes="cccccccc"
               inkscape:connector-curvature="0"
               id="path9947-4"
               d="m 885.794,406.41143 h -13.37658 l -820.776384,0.11207 -0.0062,-1.38231 -0.0014,-1.58072 820.783964,0.19507 h 13.3766 c -0.82326,0.32557 -0.84993,2.90362 0,2.65589 z"
               :fill="yaxisColor"
               style=";stroke-width:0.04997532" />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
</template>

<script>
const X_AXIS_COLOR = '#6d8fc1'
const Y_AXIS_COLOR = '#ffc976'
const FADE_COLOR = '#e0e0e0'
const DATA_COLOR = '#44c4a9'
const COLOR_COLOR = '#44c4a9'
const SHAPE_COLOR = '#000000'
const GRAD_COLOR = '#d3fff6'

export default {
  name: 'FakeGraph',
  props: {
    analysis: {
      type: String,
      default: 'univariate'
    }
  },
  data () {
    return {
      xaxisColor: X_AXIS_COLOR,
      yaxisColor: Y_AXIS_COLOR,
      dataColor: DATA_COLOR,
      refDataColor: DATA_COLOR,
      shapeColor: SHAPE_COLOR,
      gradColor: GRAD_COLOR,
      colorColor: COLOR_COLOR,
      generatedPointsCoords: [],
      generatedTrianglesCoords: []
    }
  },
  mounted () {
    if (this.isBivariate) {
      this.dataColor = SHAPE_COLOR
    }
    this.generatedPointsCoords = this.generatePoints()
    this.generatedTrianglesCoords = this.generateTriangles()
  },
  computed: {
    isUnivariate: function () {
      return this.analysis === 'univariate'
    },
    isBivariate: function () {
      return this.analysis === 'bivariate'
    },
    isMultivariate: function () {
      return this.analysis === 'multivariate'
    },
    pointsCoords: function () {
      let coords = []
      if (!this.isUnivariate) {
        coords = this.generatedPointsCoords
      }
      return coords
    },
    trianglesCoords: function () {
      let coords = []
      if (this.isMultivariate) {
        coords = this.generatedTrianglesCoords
      }
      return coords
    }
  },
  methods: {
    getEltSize (size) {
      let inSize = size
      if (this.isBivariate) {
        inSize = 10
      }
      return inSize
    },
    getEltOpacity (opacity) {
      let inOpacity = opacity
      if (this.isBivariate) {
        inOpacity = 0.6
      }
      return inOpacity
    },
    generatePoints () {
      let points = []
      for (let i = 0; i < 200; i++) {
        points.push({
          x: this.getRandomIntInclusive(i + 50, i + 550),
          y: this.getRandomIntInclusive(120 - 0.5 * i, 350 - 0.5 * i),
          size: this.getRandomIntInclusive(4, 15),
          opacity: this.getRandomIntInclusive(10, 100) / 100
        })
      }
      return points
    },
    generateTriangles () {
      let triangle = []
      let val = ''
      for (let i = 0; i < 50; i++) {
        // points="150 80, 160 100, 140 100"
        let xr = this.getRandomIntInclusive(i, i + 400)
        let yr = this.getRandomIntInclusive(i - 10, i + 100)
        let x1 = 150 + xr
        let x2 = 160 + xr
        let x3 = 140 + xr
        let y1 = 80 + yr
        let y2 = 100 + yr
        let y3 = 100 + yr
        val = '' + x1 + ' ' + y1 + ', ' + x2 + ' ' + y2 + ', ' + x3 + ' ' + y3
        triangle.push({
          points: val,
          opacity: this.getRandomIntInclusive(10, 100) / 100
        })
      }
      return triangle
    },
    getRandomIntInclusive (min, max) {
      min = Math.ceil(min)
      max = Math.ceil(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    highlightXaxis () {
      this.yaxisColor = FADE_COLOR
      this.dataColor = FADE_COLOR
      this.xaxisColor = X_AXIS_COLOR
      this.shapeColor = FADE_COLOR
      this.colorColor = FADE_COLOR
      this.gradColor = '#ffffff'
    },
    highlightYaxis () {
      this.xaxisColor = FADE_COLOR
      this.dataColor = FADE_COLOR
      this.yaxisColor = Y_AXIS_COLOR
      this.shapeColor = FADE_COLOR
      this.colorColor = FADE_COLOR
      this.gradColor = '#ffffff'
    },
    highlightShape () {
      this.xaxisColor = FADE_COLOR
      this.dataColor = SHAPE_COLOR
      this.yaxisColor = FADE_COLOR
      this.shapeColor = SHAPE_COLOR
      this.colorColor = FADE_COLOR
      this.gradColor = '#ffffff'
    },
    highlightColor () {
      this.xaxisColor = FADE_COLOR
      this.dataColor = DATA_COLOR
      this.yaxisColor = FADE_COLOR
      this.shapeColor = FADE_COLOR
      this.colorColor = COLOR_COLOR
      this.gradColor = GRAD_COLOR
    },
    highlightSize () {
      this.xaxisColor = FADE_COLOR
      this.dataColor = SHAPE_COLOR
      this.yaxisColor = FADE_COLOR
      this.shapeColor = FADE_COLOR
      this.colorColor = FADE_COLOR
      this.gradColor = '#ffffff'
    },
    reinitGraph () {
      this.yaxisColor = Y_AXIS_COLOR
      if (this.analysis === 'bivariate') {
        this.dataColor = SHAPE_COLOR
      } else {
        this.dataColor = DATA_COLOR
      }
      this.xaxisColor = X_AXIS_COLOR
      this.shapeColor = SHAPE_COLOR
      this.colorColor = COLOR_COLOR
      this.gradColor = GRAD_COLOR
    }
  }
}
</script>
